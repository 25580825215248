@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    background-color: rgb(226 232 240);
}
.paragraphs{
  white-space: pre-line;
}
